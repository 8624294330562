<template>
    <div id="smartTemplates">
      <div class="header">
        <div class="left">
          <!-- <p class="title">智能模板</p> -->
          <el-button
            type="primary"
            size="medium"
            @click="newdata"
          >
              新建智能脚本
          </el-button>
        </div>
        <div class="right">
          <span class="mr20">来源</span>
          <el-select
              v-model="post.sort"
              style="width: 150px"
              size="medium"
              placeholder="请选择"
          >
              <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              ></el-option>
          </el-select>
          <el-input
              v-model="post.search"
              size="medium"
              placeholder="输入公开课名称搜索"
              style="width: 218px"
          ></el-input>
        </div>
      </div>
      <div class="paginationBox">
        <pagination2 :option="post" url="/console/getMsgRecords" ref="childDialogBox" class="mt20 childDialogBox flex1"
          @complete="complete">
          <template v-slot:default="{ tableData }">
            <!-- class="absolute" -->
            <el-table
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              :data="tableData"
            >
              <el-table-column label="模板ID" prop="name" width="100"></el-table-column>
              <el-table-column label="名称" prop="name" width="100"></el-table-column>
              <el-table-column label="视频时长" prop="uname" width="120"></el-table-column>
              <el-table-column label="话术条数" width="150"></el-table-column>
              <el-table-column label="来源" width="100"></el-table-column>
              <el-table-column label="添加人"  width="80"></el-table-column>
              <el-table-column label="添加时间"  width="80"></el-table-column>
              <el-table-column label="操作" align="center" min-width="180">
                <template slot-scope="{ row }">
                  <el-button type="text" style="color: #FA6400" @click="edit(row)">
                    编辑
                  </el-button>
                  <el-button type="text" style="color: #FA6400" @click="allocate(row)">
                    配置
                  </el-button>
                  <el-button type="text" style="color: #FA6400" @click="del(row)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </pagination2>
      </div>
    </div>
</template>

<script>
import bg from '@/mixin/background'
export default {
  name:'smartTemplates',
  mixins: [bg],
  data(){
    return {
      post:{
        sort:0,
        search:'',
      },
      iscomplete: false,
      tableList:[],
      options: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '直播生成',
        },
        {
          value: 2,
          label: '手动增加',
        },
      ],
    }
  },
  filters:{
    getStatus(e){
      let str = ''
      switch (Number(e)) {
        case 1:
          str='直播生成'
          break;
        case 2:
          str='手动增加'
          break;
      }
      return str
    },
  },
  methods:{
    newdata(){},
    edit(){},
    allocate(){},
    del(){},
    // 获取列表数据
    complete(val) {
      this.tableList = val.data
      this.iscomplete = true
    },
  },
}
</script>

<style scoped lang="scss">
#smartTemplates{
  margin: 20px;
  min-width: 868px;
  background-color: #fff;
  .header {
    font-size: 13px;
    padding: 20px 20px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      .title {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }
  .paginationBox {}
}
</style>